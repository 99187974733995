<template>
  <b-container>
    <div class="change-pass">
      <div class="d-flex align-items-center mt-2 mb-2 px-3">
        <h4 class="mr-auto mb-0">Đổi mật khẩu</h4>
        <b-button variant="success" size="sm" @click="save">Lưu</b-button>
      </div>
      <b-card class="mb-2 card-border-top">
        <b-input-group class="mb-2 mt-3" size="sm" prepend="Mật khẩu cũ:">
          <b-form-input type="password" v-model="oldPass"></b-form-input>
        </b-input-group>
        <b-input-group class="mb-2" size="sm" prepend="Mật khẩu mới:">
          <b-form-input type="password" v-model="newPass"></b-form-input>
        </b-input-group>
        <b-input-group class="mb-2" size="sm" prepend="Nhập lại mật khẩu mới:">
          <b-form-input type="password" v-model="confirmPass"></b-form-input>
        </b-input-group>
      </b-card>
    </div>
  </b-container>
</template>
<script>
// @ is an alias to /src

export default {
  name: "ChangePass",
  data() {
    return {
      oldPass: "",
      newPass: "",
      confirmPass: ""
    };
  },
  methods: {
    async save() {
      if (this.oldPass == "") {
        this.makeToast(
          "Lỗi",
          "Mật khẩu cũ không được để trống",
          "danger",
          true
        );
        return;
      }

      if (this.newPass == "") {
        this.makeToast(
          "Lỗi",
          "Mật khẩu mới không được để trống",
          "danger",
          true
        );
        return;
      }

      if (this.newPass !== this.confirmPass) {
        this.makeToast("Lỗi", "Mật khẩu mới không khớp nhau", "danger", true);
        return;
      }

      let vm = this;
      try {
        let res = await this.$http.put("/user/password", {
          old_password: this.oldPass,
          password_confirmation: this.confirmPass,
          password: this.newPass
        });

        if (res.data.code == 0) {
          this.makeToast(
            "Thành công",
            "Đổi mật khẩu thành công",
            "success",
            true
          );
        } else {
          this.makeToast("Lỗi", res.data.msg, "danger", true);
        }

        return;
      } catch (err) {
        if (err.response && err.response.status == 422) {
          if (err.response.data.errors) {
            let errors = err.response.data.errors;
            for (let err in errors) {
              vm.makeToast("Lỗi", errors[err].join("<br/>"), "danger", true);
            }
          }
        }

        return;
      }
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    }
  }
};
</script>
<style>
.change-pass .input-group-text {
  width: 160px;
}
</style>
